import React from 'react'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

let vendors = require('../data/vendor.json')

class PreferredVendor extends React.Component {
	render() {
		return (
			<Layout head={{ title: 'Preferred Vendors' }}>
				<CommonHero pageTitle="Preferred Vendors" />
				<div
					id="prefered-vendors"
					className="py-5"
					style={{
						backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1555485203/brianboals/stump.jpg)`,
						backgroundSize: `cover`,
						backgroundPosition: `center`,
					}}
				>
					<div className="container">
						<h5 className="mb-5 text-center">
							We work with a premier group of vendors to help our clients
							experience a smooth and easy home buying and selling process. We
							are proud to share our partners with you.
						</h5>
						<div className="row">
							{vendors.map(vendor => (
								<div className="col-md-4 col-sm-6 mb-4" key={vendor.id}>
									<div className="vendor text-center card">
										<div className="avatar">
											<LazyLoadImage
												className="img-fluid"
												effect="blur"
												src={
													vendor.profile
														? vendor.profile
														: 'https://img.icons8.com/carbon-copy/300/000000/user-male.png'
												}
												alt={vendor.name}
											/>
										</div>
										<h4>{vendor.name}</h4>
										<p className="mb-0">{vendor.position}</p>
										<p>
											<small>{vendor.desc}</small>
										</p>
										<a
											href={`tel:${vendor.number}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{vendor.number}
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default PreferredVendor
